<template>
  <div>
    <!-- Start card for float effect -->
    <v-card class="TableWrapper">
      <!-- Start simple table -->
      <v-simple-table fixed-header height="490px">
        <template v-slot:default>
          <thead v-if="!bLoading">
            <tr>
              <th class="text-left" style="border-bottom: 3px solid #e4e4e4">
                ID
              </th>
              <th class="text-left" style="border-bottom: 3px solid #e4e4e4">
                Proyecto
              </th>
              <th class="text-left" style="border-bottom: 3px solid #e4e4e4">
                Residente
              </th>
              <th class="text-left" style="border-bottom: 3px solid #e4e4e4">
                Fecha
              </th>
              <th
                class="text-left"
                style="border-bottom: 3px solid #e4e4e4"
              ></th>
              <th
                class="text-left"
                style="border-bottom: 3px solid #e4e4e4; width: 120px"
              >
                Acción
              </th>
            </tr>
          </thead>
          <tbody v-if="!bLoading">
            <tr v-for="(item, index) in items" :key="index">
              <td class="text-left">#{{ item.sProjectId.slice(-5) }}</td>
              <td class="text-left">{{ item.sProjectName }}</td>
              <td class="text-left">{{ item.sResidentName }}</td>
              <td class="text-left">{{ item.dDate }}</td>
              <td class="text-left"></td>
              <td>
                <div>
                  <v-btn icon color="black" @click="viewDetails(item)">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                  <v-btn icon color="#FF5757" @click="deleteRequest(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </td>
            </tr>
          </tbody>
          <div v-show="bLoading">
            <v-progress-linear
              indeterminate
              color="yellow darken-2"
            ></v-progress-linear>
            <div class="content-text-loading">
              <p class="text-loading">Cargando... Espere por favor</p>
            </div>
          </div>
        </template>
      </v-simple-table>
      <!-- End simple table -->
      <!-- Start pagination -->
      <div class="footer-table">
        <div style="margin-right: 30px">
          <p>
            Filas por página:
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="footer-table-dropdown"
                  text
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ iItemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="changeTableContacts(10)">
                  <v-list-item-title>10</v-list-item-title>
                </v-list-item>
                <v-list-item @click="changeTableContacts(25)">
                  <v-list-item-title>25</v-list-item-title>
                </v-list-item>
                <v-list-item @click="changeTableContacts(50)">
                  <v-list-item-title>50</v-list-item-title>
                </v-list-item>
                <v-list-item @click="changeTableContacts(100)">
                  <v-list-item-title>100</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </p>
        </div>
        <div style="margin-right: 15px">
          <p>{{ iCurrentPage }}-{{ numPages }} de {{ totalItems }}</p>
        </div>
        <div>
          <v-btn
            elevation="0"
            class="footer-table-buttons"
            @click="backPageContacts"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            elevation="0"
            class="footer-table-buttons"
            @click="nextPageContacts"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
      <!-- End pagination -->
    </v-card>
    <!-- Start new order: modal -->
    <NewPurchaseOrder
      @close="openDialogOrder = false"
      :openDialogOrder="openDialogOrder"
      :itemDetails="itemDetails"
      :resquestMaterials="resquestMaterials"
    ></NewPurchaseOrder>
    <!-- End new order: modal -->
    <!-- End card for float effect -->
    <!-- @deleteRequestItem="deleteRequest" : Agregar en caso de que truene-->
    <ViewDetails
      @openDialogNewPurchaseOrder="openDialogNewPurchaseOrder"
      @close="openDialogDetails = false"
      :openDialogDetails="openDialogDetails"
      :itemDetails="itemDetails"
    ></ViewDetails>
  </div>
</template>
<script>
import ViewDetails from "@/components/views/Requests/Details";
import NewPurchaseOrder from "@/components/views/Requests/NewOrder";

export default {
  name: "RequestsComponent",
  mounted() {
    this.getItems();
  },
  components: {
    ViewDetails,
    NewPurchaseOrder,
  },
  data() {
    return {
      bLoading: false,
      // number of pages
      iCurrentPage: 1,
      iPageNumber: 1,

      items: [],
      iItemsPerPage: 50,
      numPages: 0,
      totalItems: 0,
      openDialogDetails: false,
      itemDetails: {},
      openDialogOrder: false,
      resquestMaterials: [],
      permisionUser: localStorage.getItem("permisionUser"),
    };
  },
  methods: {
    openDialogNewPurchaseOrder(e, i) {
      this.openDialogOrder = true;
      this.resquestMaterials = e;
      this.itemDetails = i;
    },
    changeTableContacts(e) {
      this.iItemsPerPage = e;
      this.iCurrentPage = 1;
      this.getItems();
    },
    backPageContacts() {
      if (this.iCurrentPage > 1) {
        this.iCurrentPage--;
      }
    },
    nextPageContacts() {
      if (this.iCurrentPage < this.numPages) {
        this.iCurrentPage++;
      }
    },
    //view details of request
    viewDetails(e) {
      this.openDialogDetails = true;
      this.itemDetails = e;
    },
    //delete request one to one
    deleteRequest(item) {
      this.$store.commit("setDeleteItems", {
        id: item.sProjectId,
        route: "/api/v1/material_request/" + item.sPurchaseMaterialsRequestId,
        unit: "solicitudes de material.",
        msgDelete: "Estás a punto de eliminar un elemento",
        msgQuestionDelete: "¿Estás seguro que deseas realizar esta acción?",
      });
    },
    // get the items from api
    getItems() {
      this.bLoading = true;
      // get items
      db.get(`${uri}/api/v1/material_request`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
        params: {
          iPageNumber: this.iCurrentPage,
          sSearch: this.sSearch,
          iItemsPerPage: this.iItemsPerPage,
          sArea: this.sArea,
          sProjectStatusId: this.sStatus,
          iMRFilter: this.iMRFilter,
        },
      })
        .then((resp) => {
          this.bLoading = false;
          this.numPages = resp.data.iNumPages;
          this.iPageNumber = resp.data.iNumPages;
          this.items = resp.data.materialRequest;
          this.totalItems = resp.data.iTotal;
          this.$store.commit("refresher", false);
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
  },
  destroyed() {
    this.$store.commit("setIMRFilter", 1);
  },
  computed: {
    // return headers from texts
    arrHeaders() {
      return this.texts.headers;
    },
    // set search variable for filter option
    sSearch() {
      return this.$store.state.sSearch;
    },
    startDate() {
      return this.$store.state.dStartDate;
    },
    endDate() {
      return this.$store.state.dEndDate;
    },
    sStatus() {
      return this.$store.state.sStatus;
    },
    sArea() {
      return this.$store.state.sArea;
    },
    token() {
      return "Bearer " + this.$store.state.token;
    },
    refreshTable() {
      return this.$store.state.refresh;
    },
    iMRFilter() {
      return this.$store.state.iMRFilter;
    },
  },
  watch: {
    sArea: function () {
      this.iCurrentPage = 1;
      this.getItems();
    },
    sStatus: function () {
      this.iCurrentPage = 1;
      this.getItems();
    },
    sSearch: function () {
      this.iCurrentPage = 1;
      this.getItems();
    },
    endDate: function () {
      if (this.endDate) {
        this.iCurrentPage = 1;
        this.getItems();
      }
    },
    startDate: function () {
      this.iCurrentPage = 1;
      this.getItems();
    },
    iCurrentPage: function () {
      this.getItems();
    },
    refreshTable: function () {
      this.getItems();
    },
    iMRFilter: function () {
      this.iCurrentPage = 1;
      this.getItems();
    },
  },
  props: {
    // import texts from admin
    texts: Object,
    mdUp: Boolean,
    smUp: Boolean,
  },
};
</script>
<style scoped>
.actionsIcon {
  font-size: 20px !important;
}

.TableWrapper {
  padding: 15px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 40px #00000019 !important;
  border-radius: 20px !important;
  width: 100%;
  margin-bottom: 25px;
}

.footer-table-dropdown {
  opacity: 0.54;
  color: #475f7b;
  font-size: 12px;
  letter-spacing: 0;
}

.table-contacts thead {
  background-color: #f7f8ff;
}

.table-contacts thead tr th {
  border-bottom: 1px solid #f7f8ff !important;
  color: #47567b !important;
  opacity: 0.87;
  font-size: 13px !important;
  letter-spacing: 0;
  line-height: 15px;
  font-weight: 500;
}

.table-contacts tbody tr td {
  color: #250e62;
  opacity: 0.87;
  font-size: 13px !important;
  letter-spacing: 0;
  border-bottom: 0.5px solid #979797 !important;
}

.footer-table {
  background-color: #fff;
  height: 56px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.footer-table p {
  margin-bottom: 0;
  color: #696969;
  font-size: 12px;
  letter-spacing: 0;
}

.footer-table-buttons {
  background-color: #fff !important;
}

.footer-table-buttons i {
  font-size: 24px;
  opacity: 0.54;
  color: #475f7b !important;
}
</style>