<template>
  <div>
    <v-dialog
      persistent
      v-model="openDialogDetails"
      width="1100"
      style="z-index: 999999999999999999"
    >
      <v-card style="border-radius: 10px; padding: 20px !important">
        <v-icon @click="close" class="dp-icon-modal">mdi-close</v-icon>
        <v-container>
          <v-row>
            <v-col>
              <p class="dp-title-modal" style="text-align: center">
                Detalle solicitud de material
              </p>
            </v-col>
          </v-row>
          <hr style="height: 1px; background-color: #ffce00; border: none" />
          <v-row>
            <v-col cols="12" sm="6">
              <div>
                <p class="text-details-requests">Proyecto</p>
                <p class="subtext-details-requests">{{ proyectoNombre }}</p>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <div>
                <p class="text-details-requests">Residente</p>
                <p class="subtext-details-requests">{{ residenteNombre }}</p>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12">
              <div>
                <!-- Start simple table -->
                <v-card style="border-radius: 20px; padding: 20px !important">
                  <v-simple-table fixed-header height="280px">
                    <template v-slot:default>
                      <thead v-if="!bLoading">
                        <tr>
                          <th
                            class="text-left"
                            style="
                              border-bottom: 3px solid #e4e4e4;
                              width: 40px;
                            "
                          ></th>
                          <th
                            class="text-left"
                            style="border-bottom: 3px solid #e4e4e4"
                          >
                            Concepto
                          </th>
                          <th
                            class="text-left"
                            style="border-bottom: 3px solid #e4e4e4"
                          >
                            Cantidad
                          </th>
                          <th
                            class="text-left"
                            style="border-bottom: 3px solid #e4e4e4"
                          >
                            Unidad de medida
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="!bLoading">
                        <tr
                          v-for="(item, index) in itemsRequestPerMaterial"
                          :key="index"
                        >
                          <td class="text-left">
                            <v-checkbox
                              v-model="materialesSeleccionados"
                              :value="index"
                            >
                            </v-checkbox>
                          </td>
                          <td class="text-left">{{ item.sMaterialName }}</td>
                          <td class="text-left">{{ item.dQuantity }}</td>
                          <td class="text-left">{{ item.sMeasureUnitName }}</td>
                        </tr>
                      </tbody>
                      <div v-show="bLoading">
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                        <div class="content-text-loading">
                          <p class="text-loading">
                            Cargando... Espere por favor
                          </p>
                        </div>
                      </div>
                    </template>
                  </v-simple-table>
                </v-card>
                <!-- End simple table -->
              </div>
            </v-col>
          </v-row>
          <br />
          <v-row>
            <v-col cols="12" sm="6">
              <div>
                <v-btn
                  x-large
                  color="red"
                  width="175"
                  outlined
                  class="text-Poppins"
                  style="
                    height: 50px;
                    font-size: 13px;
                    font-weight: 600;
                    border-radius: 5px;
                  "
                  @click="deleteRequest"
                  >Eliminar solicitud
                  <v-icon size="15">mdi-delete</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col
              cols="12"
              :sm="
                aAllows.includes('PurchaseOrder') ||
                aAllows.includes('Administrator')
                  ? '3'
                  : '6'
              "
              class="text-right"
            >
              <div>
                <v-btn
                  x-large
                  color="black"
                  width="175"
                  outlined
                  class="text-Poppins"
                  @click="close"
                  style="
                    height: 50px;
                    font-size: 13px;
                    font-weight: 600;
                    border-radius: 5px;
                  "
                  >Cerrar
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" sm="3" class="text-right">
              <div>
                <v-btn
                  v-if="
                    aAllows.includes('PurchaseOrder') ||
                    aAllows.includes('Administrator')
                  "
                  x-large
                  color="primary"
                  class="text-Poppins"
                  style="
                    width: 100%;
                    height: 50px;
                    color: black;
                    box-shadow: 0px 20px 40px #00000029;
                    border-radius: 5px;
                    font-size: 13px;
                    font-weight: 600;
                  "
                  :disabled="!validateGenerarOrderCompra"
                  @click="generarOrdenCompra"
                  >Generar orden de compra
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "DetailsRequests",
  props: {
    openDialogDetails: {
      type: Boolean,
      default: false,
    },
    itemDetails: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      bLoading: false,
      proyectoNombre: "",
      residenteNombre: "",
      itemsRequestPerMaterial: [],
      itemsRequests: [],
      materialesSeleccionados: [],
      permisionUser: localStorage.getItem("permisionUser"),
      aAllows: this.$store.state.aAllows.split(","),
    };
  },
  methods: {
    close() {
      this.$emit("close");
      this.materialesSeleccionados = [];
    },
    allData() {
      this.getMaterialResquest();
      this.proyectoNombre = this.itemDetails.sProjectName;
      this.residenteNombre = this.itemDetails.sResidentName;
      this.itemsRequests = this.itemDetails;
    },
    getMaterialResquest() {
      this.bLoading = true;
      // get material per request
      db.get(
        `${uri}/api/v1/material_request/${this.itemDetails.sPurchaseMaterialsRequestId}/1`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
          params: {
            iPageNumber: "1",
            sPurchaseMaterialsRequestId:
              this.itemDetails.sPurchaseMaterialsRequestId,
          },
        }
      )
        .then((resp) => {
          this.bLoading = false;
          this.itemsRequestPerMaterial = resp.data.OrderMaterials;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    deleteRequest() {
      var item = this.itemDetails;
      this.$store.commit("setDeleteItems", {
        id: item.sProjectId,
        route: "/api/v1/material_request/" + item.sPurchaseMaterialsRequestId,
        unit: "solicitudes de material.",
        msgDelete: "Estás a punto de eliminar un elemento",
        msgQuestionDelete: "¿Estás seguro que deseas realizar esta acción?",
      });
    },
    generarOrdenCompra() {
      let arr = [];

      for (let i = 0; i < this.materialesSeleccionados.length; i++) {
        var res = this.itemsRequestPerMaterial[this.materialesSeleccionados[i]];
        arr.push(res);
      }

      this.$emit(
        "openDialogNewPurchaseOrder",
        arr.map((e) => {
          return {
            ...e,
            dUnitPrice: 0,
          };
        }),
        this.itemDetails
      );

      this.close();
    },
  },
  watch: {
    openDialogDetails: function () {
      if (this.openDialogDetails) {
        this.allData();
      }
    },
  },
  computed: {
    // picker detail dialog state
    bPickerDetails() {
      return this.$store.state.bPickerDetails;
    },
    validateGenerarOrderCompra() {
      return this.materialesSeleccionados.length > 0;
    },
  },
};
</script>

<style scoped>
.text-details-requests {
  color: #2e2e2e;
  font-weight: 600;
  margin-bottom: 5px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  margin-top: 25px;
}

.subtext-details-requests {
  color: #b6b6b6;
  text-align: center;
  font-family: "Poppins", sans-serif;
}
</style>